<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        @click="fetchSupplier()"
        color="primary"
        icon
        v-if="view !== 1"
      >
        <v-icon small> $edit </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("inventorys.edit_supplier") }}
      </v-card-title>
      <v-card-text>
        <supplier-form
          :title="$t('inventorys.edit_supplier')"
          :supplier="supplier"
          @validated="updateSuppliers()"
          @back="dialog = false"
        ></supplier-form
      ></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SupplierEdit",
  components: { SupplierForm: () => import("@/components/supplier/Form") },
  props: ["id", "view"],

  data() {
    return {
      validationErrors: [],
      supplier: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("supplier", ["updateSupplier"]),
    ...mapActions("supplier", ["getSupplier"]),
    fetchSupplier() {
      //console.log(this.id);
      this.getSupplier(this.id).then((response) => {
        this.supplier = response;
        this.supplier.brands = this.supplier.brands.map(function (item) {
          return item.id;
        });
        //console.log(this.supplier);
      });
    },
    open() {
      this.fetchSupplier();
      this.dialog = true;
    },
    updateSuppliers() {
      //console.log("update", this.supplier);
      this.updateSupplier({
        supplier_id: this.id,
        supplier: this.supplier,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("supplier_edit_success"), "", "");
          this.$emit("updated");
          this.dialog = false;
        }
      });
    },
  },
};
</script>